// NOTE:  Also includes styles for loginbu.htm.

.proxy-page.proxy-page-login {

  .page-title-wrapper {
    background-color: $secondary-blue-light;
  }
}

.proxy-page.proxy-page-loginbu {

  .proxy-message {
    // @include simple-icon('exclamation-circle', $bg-x: 4px, $bg-y: 0, $bg-size: 1.2em);
    // @include simple-icon('times-circle', $icon-size: 1.2em);
  }

}

.proxy-page.proxy-page-login,
.proxy-page.proxy-page-loginbu {

  #content {
    display: block;
    justify-content: space-between;
  }

  .proxy-login-form {
    width: 100%;

    #login_box {
      border: 1px solid #ccc;
    }

    h3 {
      padding: 1rem;
      background-color: #ccc;
      color: black;
      @include font-size(2);
      line-height: 2rem;
    }

    form { 
      padding: 8px;

      #hawkidlogin,
      #hawkidpw {
        margin-bottom: 4px;

        label {
          display: inline-block;
          width: 30%;
          text-align: right;
        }
        input {
          display: inline-block;
          width: 65%;
          @include font-size(1.6);
        }
      }

      input[type='submit'] {
        float: right;
        margin-top: 1rem;
        @include simple-button;
        @include font-size(1.6);
        width: 100%;
      }

      ul {
        clear: both;
        margin-top: 8rem;
      }
    }
  }

  .proxy-message-details {
    width: 100%;
  }

  // --- BREAKPOINTS ---------------------------------------------
  @include breakpoint($smallish-width) {

    #content {
      display: flex;
    }

    .proxy-login-form {
      flex: 1;
      margin-right: 1rem;
      margin-bottom: 2rem;
    }
    
    .proxy-message-details {
      flex: 1;
    }
    
  }
  
  @include breakpoint($medium-width) {
    
    .proxy-message-details {
      flex: 2;
    }
    
  }

  @include breakpoint($fixed-width) {
    
    .proxy-message-details {
      flex: 3;
    }
  }
  // -------------------------------------------------------------
}