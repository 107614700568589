/* ---------------------------------------------------------
	NOTE:	ALL the CSS below is precompiled via SASS. 
			Any edits to CSS files will be overwritten. 
			Talk to lib-webmaster@uiowa.edu first.
   --------------------------------------------------------- */
.proxy-page .header-wrapper {
  padding-bottom: 1rem;
}
.proxy-page #main {
  width: 100%;
}
.proxy-page #main .page-title-wrapper {
  margin: 3rem 0;
  padding: 3rem 0;
}
.proxy-page #main .page-title-wrapper svg {
  display: inline-block;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  vertical-align: middle;
  width: 1.5em;
  height: 1.5em;
}
.proxy-page #main .page-title-wrapper .page-title {
  margin: 0 auto;
  padding: 0;
  max-width: 1150px;
  font-family: "Zilla Slab", serif;
  font-weight: 700;
}
.proxy-page #main #content {
  margin: 0 auto;
  padding: 0 8px 10rem 8px;
  max-width: 1150px;
}
.proxy-page #main .proxy-message {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.5;
}
.proxy-page #main .proxy-message-details ul li {
  margin-bottom: 1rem;
}
.proxy-page #main .proxy-message-details ol {
  margin: 1rem 0;
}
.proxy-page #main .proxy-message-details ol li {
  margin-bottom: 0;
}
@media (min-width: 480px) {
  .proxy-page #main .proxy-message {
    font-size: 30px;
    font-size: 3rem;
    line-height: 1.5;
  }
}
@media (min-width: 1150px) {
  .proxy-page #main {
    padding: 0;
  }
}

.proxy-page.proxy-page-login .page-title-wrapper {
  background-color: #bfe6ff;
}

.proxy-page.proxy-page-login #content,
.proxy-page.proxy-page-loginbu #content {
  display: block;
  justify-content: space-between;
}
.proxy-page.proxy-page-login .proxy-login-form,
.proxy-page.proxy-page-loginbu .proxy-login-form {
  width: 100%;
}
.proxy-page.proxy-page-login .proxy-login-form #login_box,
.proxy-page.proxy-page-loginbu .proxy-login-form #login_box {
  border: 1px solid #ccc;
}
.proxy-page.proxy-page-login .proxy-login-form h3,
.proxy-page.proxy-page-loginbu .proxy-login-form h3 {
  padding: 1rem;
  background-color: #ccc;
  color: black;
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.5;
  line-height: 2rem;
}
.proxy-page.proxy-page-login .proxy-login-form form,
.proxy-page.proxy-page-loginbu .proxy-login-form form {
  padding: 8px;
}
.proxy-page.proxy-page-login .proxy-login-form form #hawkidlogin,
.proxy-page.proxy-page-login .proxy-login-form form #hawkidpw,
.proxy-page.proxy-page-loginbu .proxy-login-form form #hawkidlogin,
.proxy-page.proxy-page-loginbu .proxy-login-form form #hawkidpw {
  margin-bottom: 4px;
}
.proxy-page.proxy-page-login .proxy-login-form form #hawkidlogin label,
.proxy-page.proxy-page-login .proxy-login-form form #hawkidpw label,
.proxy-page.proxy-page-loginbu .proxy-login-form form #hawkidlogin label,
.proxy-page.proxy-page-loginbu .proxy-login-form form #hawkidpw label {
  display: inline-block;
  width: 30%;
  text-align: right;
}
.proxy-page.proxy-page-login .proxy-login-form form #hawkidlogin input,
.proxy-page.proxy-page-login .proxy-login-form form #hawkidpw input,
.proxy-page.proxy-page-loginbu .proxy-login-form form #hawkidlogin input,
.proxy-page.proxy-page-loginbu .proxy-login-form form #hawkidpw input {
  display: inline-block;
  width: 65%;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
}
.proxy-page.proxy-page-login .proxy-login-form form input[type=submit],
.proxy-page.proxy-page-loginbu .proxy-login-form form input[type=submit] {
  float: right;
  margin-top: 1rem;
  display: inline-block;
  padding: 0.7em 2em 0.55em;
  outline: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none !important;
  font-family: inherit;
  transition: 0.1s;
  background-color: #ffcd00;
  color: black !important;
  border: none;
  line-height: 1.5em;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
  width: 100%;
}
.proxy-page.proxy-page-login .proxy-login-form form input[type=submit]:hover,
.proxy-page.proxy-page-loginbu .proxy-login-form form input[type=submit]:hover {
  text-decoration: none;
  background-color: #ffd733;
  color: black;
}
.proxy-page.proxy-page-login .proxy-login-form form input[type=submit]:active,
.proxy-page.proxy-page-loginbu .proxy-login-form form input[type=submit]:active {
  position: relative;
  top: 1px;
  background-color: #ffcd00;
  color: black;
}
.proxy-page.proxy-page-login .proxy-login-form form input[type=submit]:focus,
.proxy-page.proxy-page-loginbu .proxy-login-form form input[type=submit]:focus {
  outline: 2px solid #00558c;
}
.proxy-page.proxy-page-login .proxy-login-form form input[type=submit] i,
.proxy-page.proxy-page-loginbu .proxy-login-form form input[type=submit] i {
  margin-right: 0.333em;
}
.proxy-page.proxy-page-login .proxy-login-form form ul,
.proxy-page.proxy-page-loginbu .proxy-login-form form ul {
  clear: both;
  margin-top: 8rem;
}
.proxy-page.proxy-page-login .proxy-message-details,
.proxy-page.proxy-page-loginbu .proxy-message-details {
  width: 100%;
}
@media (min-width: 600px) {
  .proxy-page.proxy-page-login #content,
  .proxy-page.proxy-page-loginbu #content {
    display: flex;
  }
  .proxy-page.proxy-page-login .proxy-login-form,
  .proxy-page.proxy-page-loginbu .proxy-login-form {
    flex: 1;
    margin-right: 1rem;
    margin-bottom: 2rem;
  }
  .proxy-page.proxy-page-login .proxy-message-details,
  .proxy-page.proxy-page-loginbu .proxy-message-details {
    flex: 1;
  }
}
@media (min-width: 855px) {
  .proxy-page.proxy-page-login .proxy-message-details,
  .proxy-page.proxy-page-loginbu .proxy-message-details {
    flex: 2;
  }
}
@media (min-width: 1150px) {
  .proxy-page.proxy-page-login .proxy-message-details,
  .proxy-page.proxy-page-loginbu .proxy-message-details {
    flex: 3;
  }
}

.proxy-page.proxy-page-menu .page-title-wrapper {
  background-color: #ccfff4;
}

.proxy-page.proxy-page-logout .proxy-message {
  text-align: left;
  padding-left: calc(1.2em * 1.333 + 0em);
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  line-height: 1.5em;
  color: black;
  background-image: url(/includes/icons/svg/black/sign-out-alt.svg);
  background-repeat: no-repeat;
  background-size: 1.2em;
  background-position: left 0em top 0.35em;
}

.proxy-page.proxy-page-loginbu .page-title-wrapper {
  background-color: #f1cbc2;
}