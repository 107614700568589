@mixin breakpoint($point) {

  @media (min-width: $point) { 
      @content; 
  }
}

.proxy-page {

  // --- header
  .header-wrapper {
    padding-bottom: 1rem;
  }
  // .site-name {
  //   color: white;
  //   @include font-size(2.6);
  // }

  // --- nav
  // #nav {
  //   display: none;
  // }

  // --- main content area
  #main {
    width: 100%;

    .page-title-wrapper {
      margin: 3rem 0;
      padding: 3rem 0;

      svg {
        display: inline-block;
        margin-right: 1rem;
        margin-bottom: 0.5rem;
        vertical-align: middle;
        width: 1.5em;
        height: 1.5em;
      }

      .page-title {
        margin: 0 auto;
        padding: 0;
        max-width: $fixed-width;
        font-family: $font-stack-headers-2;
        font-weight: 700;
      }
    }

    #content {
      margin: 0 auto;
      padding: 0 8px 10rem 8px;
      max-width: $fixed-width;
    }
  
    .proxy-message {
      @include font-size(2);
      // font-weight: lighter;
    }

    .proxy-message-details {

      ul {

        li {
          margin-bottom: 1rem;
        }
      }

      ol {
        margin: 1rem 0;

        li {
          margin-bottom: 0;
        }
      }
    }
  }


  // --- BREAKPOINTS ---------------------------------------------
  @include breakpoint($small-width) {
    
    #main {

      .proxy-message {
        @include font-size(3);
      }
    }
  }

  @include breakpoint($fixed-width) {
    
    #main {
      padding: 0;
    }
  }
  // -------------------------------------------------------------
}


